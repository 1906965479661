.container{
    margin:2rem auto;
    width:70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gridContainer{
    display: grid;
    grid-template-columns: repeat(3,20rem);
    grid-template-rows: 20rem;
}
.itemCard{
    margin:2rem;
}
.gridContainer2{
    margin: 0 10rem;
    display: grid;
    grid-template-columns: repeat(2,20rem);
    grid-template-rows: 20rem;
}


.logo{
    height:8rem;
    width:8rem;
}

.itemText{
    font-size:2rem;
    color:white;
}