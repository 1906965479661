.container{
    width:50rem;
    padding:4rem 2rem;
    margin:2rem;
    background-color: white;
    border-radius: 2rem;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.info{
    display:flex;
    padding: 2rem 3rem 2rem 2rem;
    flex-direction: column;
    justify-content: space-between;
}
.title{
    font-size: 3rem;
    font-weight: 600;
    color: #666666;
    text-align: center;
}
.description{
    font-size: 2rem;
    font-weight: 500;
    color: #777777;
    text-align: center;
}
.links{
    margin:0 0 .5rem 0;
    padding:1rem 1rem;
    border: 1rem #f2b922 double;
}
.urlLink{  
    display:block;
    padding: 0;
    margin: .5rem 0;
    font-size: 2.2rem;
    line-height: 2.4ren;
    font-weight: 600;
    color:#243b81;
    text-align: center;
    text-decoration: none;   
}

.urlLink:hover{
    cursor:pointer;
    color:#7fbcbb;
}

.imageContainer{
    display:flex;
}
.image {
    display:flex;
    border:.2rem black solid;
    border-radius:1rem;
    flex-direction: column;
    width:20rem;
   
}

/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
 
.container{
    width:38rem;
    padding: 1rem 1rem;
    margin:1rem 1rem;
}


.info{
    padding:1rem 1rem 1.5rem 1rem;    
}
.title{
    font-size: 2.5rem;
}
.description{
    font-size: 1.5rem;
    font-weight: 500;
    color: #777777;
    text-align: center;
}
.urlLink{
    padding:1rem 1rem;
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 600;
}
.image {
    width:16rem;
    margin: auto auto;
}
}

/**************************/
/* BELOW 60em             */
/**************************/

@media (max-width: 60em) {
 
    .container{
        width:30rem;
        padding: .5rem 1rem;
        margin:.5rem 1rem;
    
    }
    
    
    .info{
        padding:1rem 1rem 1.5rem 1rem;    
    }
    .title{
        font-size: 2rem;
    }
    .description{
        font-size: 1.4rem;
    }
    .urlLink{
        padding:.5rem .5rem;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 600;
    }
    .image {
        width:12rem;
        margin-bottom: 1rem;
    }
    
}
/**************************/
/* BELOW 40em             */
/**************************/

@media (max-width: 40em) {
 
    .container{
        width:18rem;
        padding: .5rem 1rem;
        margin:.5rem 1rem;
        flex-direction: column;
    }
    
    
    .info{
        padding:1rem 1rem 1.5rem 1rem;    
    }
    .title{
        font-size: 1.6rem;
    }
    .description{
        font-size: 1.4rem;
    }
    .urlLink{
        padding:.5rem .5rem;
        font-size: 1.6rem;
        line-height: 1.8rem;
    }
    .image {
        width:12rem;
    }
    
}